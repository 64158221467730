import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'usersManager/state',
    initialState: {
        deleteConfirmation: false,
        selectedUser: '',
        selectedDashboardTemplate: '',
        selectedUserDetails: {},
        
        originalDashboardConfiguration: {},
        defaultDashboardConfiguration: {},
                deleteConfirmationTemplate: false,
        editConfirmationTemplate: false,

        defaultDashboardConfigurationId: '',

        selectedRowData: {id: null},

        openDialogEdit: false,
        selectedUserId: ""
    },
    reducers: {
        setSelectedUserId: (state, action) => {
            state.selectedUserId = action.payload
        },

        toggleOpenDialog: (state, action) => {
            state.openDialogEdit = action.payload
        },

        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleDeleteConfirmationTemplate: (state, action) => {
            state.deleteConfirmationTemplate = action.payload
        },

        toggleEditConfirmationTemplate: (state, action) => {
            state.editConfirmationTemplate = action.payload
        },

        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setSelectedUserDetails: (state, action) => {
            state.selectedUserDetails = action.payload
        },
        setSelectedDashboardTemplate: (state, action) => {
            state.selectedDashboardTemplate = action.payload
        },

        setOriginalDashboardConfiguration: (state, action) => {
            state.originalDashboardConfiguration = action.payload
        },

        setDefaultDashboardConfiguration: (state, action) => {
            state.defaultDashboardConfiguration = action.payload
        },

        setDefaultDashboardConfigurationId: (state, action) => {
            state.defaultDashboardConfigurationId = action.payload
        },

        setSelectedRowData: (state, action) => {
            state.selectedRowData = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedUser, setSelectedDashboardTemplate, selectedUserDetails, setOriginalDashboardConfiguration, setDefaultDashboardConfiguration, toggleDeleteConfirmationTemplate, setDefaultDashboardConfigurationId, toggleEditConfirmationTemplate, setSelectedRowData, toggleOpenDialog, setSelectedUserId, setSelectedUserDetails } =
    stateSlice.actions

export default stateSlice.reducer
