import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'basket/state',
    initialState: {
        basketItems: [],
        openBasket: false,
        basketTotal: 0
    },
    reducers: {
        toggleBasket: (state, action) => {
            state.openBasket = !state.openBasket
        },
        addToBasket: (state, action) => {
            state.basketItems = [action.payload, ...state.basketItems]

            state.basketTotal = state.basketItems.reduce(
                (accumulator, currentValue) => accumulator + parseFloat(currentValue.price),
                0
              );
        },
        removeFromBasket: (state, action) => {
            state.basketItems = state.basketItems.filter(
                (product) => product.id !== action.payload.id
            )

            state.basketTotal = state.basketItems.reduce(
                (accumulator, currentValue) => accumulator + parseFloat(currentValue.price),
                0
              );
        },
        clearBasket: (state, action) => {
            state.basketItems = []
        },
    },
})

export const { addToBasket, removeFromBasket, toggleBasket, clearBasket } =
    stateSlice.actions

export default stateSlice.reducer
