import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import courses from './courses'
import firebase from './firebase/firebaseSlice'
import informationBlocks from './informationBlocks'
import surveyBlocks from './surveyBlocks'
import lessons from './lessons'
import tickets from './tickets'
import users from './users'
import courseProgress from './courseProgress'

import surveyBlockResults from './surveyBlockResults'
import myCourseManager from './myCourseManager'

import basket from './basket'

import instructors from './instructors'

import myTicketsManager from './myTicketsManager'

import finances from './finances'

import uiReducer from './actions/actions'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        courses,
        firebase,
        informationBlocks,
        surveyBlocks,
        lessons,
        tickets,
        users,
        courseProgress,
        surveyBlockResults,
        myCourseManager,
        basket,
        instructors,
        myTicketsManager,
        finances,
        uiReducer,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
