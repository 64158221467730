import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  list,
  getBlob,
} from "firebase/storage";

import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendPasswordResetEmail,
  updateProfile,
  getAdditionalUserInfo,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  where,
  updateDoc,
  orderBy,
  limit,
  arrayRemove,
  arrayUnion,
  serverTimestamp,
  increment,
  collectionGroup
} from "firebase/firestore";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};


const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(firebaseApp);


const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
  db,
  auth,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  createUserWithEmailAndPassword,
  storage,
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  sendPasswordResetEmail,
  ref,
  uploadBytes,
  getDownloadURL,
  where,
  updateDoc,
  orderBy,
  limit,
  deleteObject,
  list,
  getBlob,
  arrayRemove,
  arrayUnion,
  onAuthStateChanged,
  updateProfile,
  serverTimestamp,
  increment,
  getAdditionalUserInfo,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  collectionGroup,
  analytics,
};
