import React, { useEffect, useRef } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { useLocation } from 'react-router-dom';
import { TimeTrackingProvider } from './TrackTime';
import { logEvent } from 'firebase/analytics';
import { analytics } from './configs/firebase.config';

const environment = process.env.NODE_ENV

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}

function TrackUserEngagement() {
    const location = useLocation();
    const focusTimeRef = useRef(null);
    const totalTimeRef = useRef(0);
  
    const startFocusTimer = () => {
      focusTimeRef.current = new Date();
    };
  
    const stopFocusTimer = () => {
      // if (focusTimeRef.current) {
      //   const endTime = new Date();
      //   const timeSpent = (endTime - focusTimeRef.current) / 1000; // time in seconds
      //   totalTimeRef.current += timeSpent;
      //   console.log('total time: ', totalTimeRef)
      //   focusTimeRef.current = null;
      // }
    };
  
    const logEngagementTime = () => {
      stopFocusTimer();
      logEvent(analytics, 'time_on_page', {
        page_path: location.pathname,
        engagement_time: totalTimeRef.current, // time in seconds
      });
      totalTimeRef.current = 0; // Reset after logging
    };
  
    useEffect(() => {
      const handleFocus = () => {
        startFocusTimer();
      };
  
      const handleBlur = () => {
        stopFocusTimer();
      };
  
      window.addEventListener('focus', handleFocus);
      window.addEventListener('blur', handleBlur);
      window.addEventListener('beforeunload', logEngagementTime);
  
      return () => {
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('beforeunload', logEngagementTime);
      };
    }, [location]);
  
    useEffect(() => {
      logEvent(analytics, 'page_view', {
        page_path: location.pathname,
      });
      // Log engagement time when navigating to a new page
      return () => {
        logEngagementTime();
      };
    }, [location]);
  
    return null;
  }



function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <TrackUserEngagement />
                    <Theme>
                        <Layout />
                    </Theme>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App
