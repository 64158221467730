import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'
import { db, doc, updateDoc, serverTimestamp } from 'configs/firebase.config'
import { useNavigate } from 'react-router-dom'
import { updateInformationBlockStatus } from './updateInformationBlockStatusThunk'
import fetchInformationBlockFromFirebase from './fetchInformationBlockFromFirebase'
import { useDispatch } from 'react-redux'
import { showPopup } from 'store/actions/actions'

const initialState = {
    next_lesson_index: 0, //Adding tracker state to try and update nextLessonIndex properly 
    current_lesson: '',
    current_lesson_index: 0,
    course_progress: {},
    retry_counter: 3,
    firebasedatas: [],
    isLoading: false,
    error: null,

    currentUser: {},
    customerList: [],
    curriculumnList: {},
    userList: [],
    settings: {},
    initialFlag: false,
    currentUserEmailList: {
        emails: [],
    },

    accountIsClosed: false,
    snackbar: false,
    snackMessage: '',
    snackMessageColor: '',
    customerMap: {},
    userMap: {},
    quizMap: {},
    programList: [],

    contentList: [],
    quizList: [],
    lessonList: [],
    courseList: [],
    careerList: [],
    industryList: [],

    users: {
        userMap: {},
        userList: [],
    },

    jobDoc: {
        id: '',
        description: '',
        name: '',
        careerProgramArray: [],

        collaborators: [],

        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        applicationArray: [],

        careerProgramsThatAreUsingTheTestBlock: [],
        coursesThatAreUsingTheTestBlock: [],
        industriesThatAreUsingTheTestBlock: [],
    },

    industryDoc: {
        id: '',
        description: '',
        name: '',
        careerProgramArray: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        jobArray: [],
    },

    careerProgramDoc: {
        id: '',
        description: '',
        name: '',
        courseArray: [],
        industriesThatAreUsingTheCareerProgram: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        jobArray: [],
    },

    careerProgramProgress: {
        id: '',
        userId: '',
        currentCourse: '',
        careerProgramEnrolled: 'courseDocID',
        enrolledOn: '',
        courseArray: [],

        pendingCourseArray: [],
        completedCourseArray: [],
    },

    courseDoc: {
        id: '',
        description: '',
        name: '',
        price: 0,

        // submitted, under review, rejected, approved
        status: '',
        lessonsArray: [],
        careerProgramsThatAreUsingTheCourse: [],
        instructors: [],
        currentEnrolledArray: [],
        currentCompletedArray: [],
        courseRequirements: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        jobArray: [],
        historyArr: [],
        viewedCount: 0,

        totalRating: 0,
        totalRatingCount: 0,
    },

    courseProgress: {
        id: '',
        userId: '',
        currentLesson: '',
        courseEnrolled: 'courseDocID',
        enrolledOn: '',
        startedOn: '',
        completedOn: '',
        // ongoing, completed, failed
        status: '',
        highestLessonReached: 0,
        pendingLessonArray: [],
        completedLessonArray: [],

        lastModifiedOn: '',
    },

    lessonDoc: {
        id: '',
        informationBlocksArray: [],
        coursesThatAreUsingTheLesson: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',
    },

    lessonProgress: {
        id: '',
        userId: '',
        currentLesson: '',
        courseEnrolled: 'courseDocID',
        startedOn: '',
        completedOn: '',
        // ongoing, completed, failed
        status: '',
        pendingInformationBlockArray: [],
        completedInformationBlockArray: [],

        lastModifiedOn: '',
    },

    informationBlockDoc: {
        id: '',
        type: [],
        name: '',
        description: '',

        lessonsThatAreUsingThisInformationBlock: [],
        coursesThatAreUsingThisInformationBlock: [],
        informationBlocksThatAreUsingThisInformationBlock: [],

        data: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        testPoolArray: [],
        testPoolMap: {},

        requirementsArray: [],
        requirementsMap: {
            'button confirmation': false,
            'viewed on screen': false,

            timer: false,
            points: false,
            'test completion': false,
            attempts: false,
            proctored: false,

            'completely watched video': false,
            timestamp: false,
        },
    },

    userDoc: {},

    newCourseDoc: {
        id: '',
        description: '',
        name: '',
        price: 0,

        // submitted, under review, rejected, approved
        status: '',
        lessonsArray: [],
        careerProgramsThatAreUsingTheCourse: [],
        instructors: [],
        currentEnrolledArray: [],
        courseRequirements: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        jobArray: [],
    },

    newLessonDoc: {
        id: '',
        informationBlocksArray: [],
        coursesThatAreUsingTheLesson: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',
    },


    courseDoc: {
        id: '',
        description: '',
        name: '',
        price: 0,

        // submitted, under review, rejected, approved
        status: '',
        lessonsArray: [],
        careerProgramsThatAreUsingTheCourse: [],
        instructors: [],
        currentEnrolledArray: [],
        courseRequirements: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',

        jobArray: [],
    },

    newLessonDoc: {
        id: '',
        name: '',
        description: '',
        informationBlocksArray: [],
        coursesThatAreUsingTheLesson: [],

        collaborators: [],
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',
    },

    selectedCourseId: 'course1',
    selectedLessonId: 'course1-lesson1',
    selectedLessonIndex: 0,

    informationForCurrentTestToTake: {
        data: '',
        lessonId: '',
        informationBlockId: '',
        courseId: '',
        informationBlockDoc: {},
    },

    informationForCurrentTextNote: {
        description: '',
        lessonId: '',
        informationBlockId: '',
        courseId: '',
        informationBlockDoc: {},
    },

    descriptionForCurrentTextNote: '',

    courseProgress: {
        lessonsArray: [],
    },

    lessonProgress: {},

    informationBlockMapForDetails: {},

    lessonMapForDetails: {},

    courseMapForDetails: {
        course1: {
            id: 'course1',
            description: 'Take this course first.',
            name: 'Learning the Basics',
            price: 0,

            // submitted, under review, rejected, approved
            status: 'Approved',
            lessonsArray: [
                'course1-lesson1',
                'course1-lesson2',
                'course1-lesson3',
                'course1-lesson4',
                'course1-lesson5',
                'course1-lesson6',
                'course1-lesson7',
                'course1-lesson8',
            ],
            careerProgramsThatAreUsingTheCourse: [],
            instructors: [],
            currentEnrolledArray: [],
            courseRequirements: [],

            collaborators: [],
            createdBy: '',
            createdOn: '',
            lastModifiedOn: '',
            lastModifiedBy: '',
            version: 1,
            isCurrent: true,
            createdNewVersionFrom: '',
            isCloned: false,
            clonedFrom: '',

            jobArray: [],
        },

        course2: {
            id: 'course2',
            description:
                'View all the types of questions and experiencing the workflow of failing.',
            name: 'Questions and Progress',
            price: 0,

            // submitted, under review, rejected, approved
            status: 'Approved',
            lessonsArray: ['course2-lesson1', 'course2-lesson2'],
            careerProgramsThatAreUsingTheCourse: [],
            instructors: [],
            currentEnrolledArray: [],
            courseRequirements: [],

            collaborators: [],
            createdBy: '',
            createdOn: '',
            lastModifiedOn: '',
            lastModifiedBy: '',
            version: 1,
            isCurrent: true,
            createdNewVersionFrom: '',
            isCloned: false,
            clonedFrom: '',

            jobArray: [],
        },
    },

    newInformationBlockDoc: {
        id: '',
        type: '',
        description: '<p>I am the video description</p>',
        estimatedTimeForCompletion: 10,
        lessonsThatAreUsingThisInformationBlock: [],
        coursesThatAreUsingThisInformationBlock: [],
        informationBlocksThatAreUsingThisInformationBlock: [],
        data: 'https://firebasestorage.googleapis.com/v0/b/slater-40f5b.appspot.com/o/andrew_story.mp4?alt=media&token=4ba1c892-05cd-4f4d-9cec-39a137348d13',
        collaborators: [],
        createdBy: '',
        createdOn: '2023-03-27T21:38:30.874Z',
        lastModifiedOn: '2023-03-27T21:38:30.874Z',
        lastModifiedBy: '',
        version: 1,
        isCurrent: true,
        createdNewVersionFrom: '',
        isCloned: false,
        clonedFrom: '',
        testPoolArray: [],
        requirementsArray: ['Full Video'],
        requirementsMapForAdditionalInformation: { secondsPlayed: 2 },
    },

    currentTestDataToAddToInformationBlock: '',

    selectedRequirement: 'Full Video',

    retryCounter: 1,

    requirementStatus: { 'Full Video': 'Ongoing' },

    editCourse: {
        lessonsArray: [],
        lessonMap: [],
        informationBlockMap: {},
    },

    //["id1", "id2"]
    newInformationBlockTestPool: [],
    currentNewInformationBlockTestPoolIndex: 0,
    testLogicContainer: {},
    refForTestMaker: null,
    typeOfInformationBlockActionPermission: 'add',

    selectedInformationBlockToAddToLesson: '',

    selectedInformationBlockTypeToUpload: '',

    selectedInformationBlockTypeToImport: '',

    selectedInformationBlockToCreateForLesson: '',


    informationBlockStepsVisited: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
    },

    addImageCurrentStep: 0,

    imageForNoteTakingDataURL: '',

    originalCourseProgress: {},

    testResultsData: {},

    failedTest: false,
}


// type ==> image, video, test, word, pdf, excel, pptx
// image ==> import + modify, upload + modify, create
// video ==> import + modify, upload + modify, create
// word ==> import + modify, upload + modify, create
// pdf ==> import, upload
// excel ==> import, upload
// pptx ==> import, upload

export const firebaseSlice = createSlice({
    name: 'firebase',
    initialState,
    reducers: {
        addNewInformationBlockData: (state, action) => { //Commenting out entire addNewInformationBlockData
            console.log('Adding New Information Block Data:', action.payload)

            state.newInformationBlockDoc = {
                ...state.newInformationBlockDoc,
                ...action.payload,
            }
        },

        addDataToTestLogicContainer: (state, action) => { //Commenting out addataToTestLogicContainer
            state.testLogicContainer = {
                ...state.testLogicContainer,
                ...action.payload,
            }
        },

        setRefForTestMaker: (state, action) => { //Commenting out setRefTestMaker
            state.refForTestMaker = action.payload
        },

        addNewInformationBlockTestRequirements: (state, action) => { //Commenting out addNewInformationBlockData
            // console.log('action.payload', action.payload)

            state.newInformationBlockDoc = {
                ...state.newInformationBlockDoc,
                ...action.payload.informationBlockUpdatedData,
            }

            if (state.typeOfInformationBlockActionPermission === 'edit') {
                state.newInformationBlockTestPool[
                    state.currentNewInformationBlockTestPoolIndex
                ] = action.payload.newInformationBlockTestPool
            } else {
                state.newInformationBlockTestPool = [
                    ...state.newInformationBlockTestPool,
                    ...action.payload.newInformationBlockTestPool,
                ]

                state.currentNewInformationBlockTestPoolIndex += 1
            }
        },

        setSelectedRequirement: (state, action) => { //Commenting out all these set selected etc
            state.selectedRequirement = action.payload

            if (action.payload === 'Test') {
                state.newInformationBlockDoc.requirementsArray = uniq(
                    state.newInformationBlockDoc.requirementsArray
                )
            } else {
                state.newInformationBlockDoc.requirementsArray = [
                    action.payload,
                ]
            }
        },

        setSelectedInformationBlockToAddToLesson: (state, action) => {
            state.selectedInformationBlockToAddToLesson = action.payload
        },

        setSelectedInformationBlockTypeToUpload: (state, action) => {
            // console.log('action.payload', action.payload)
            state.selectedInformationBlockTypeToUpload = action.payload
        },

        setSelectedInformationBlockTypeToImport: (state, action) => {
            state.selectedInformationBlockTypeToImport = action.payload
        },

        setTypeOfInformationBlockActionPermission: (state, action) => {
            state.typeOfInformationBlockActionPermission = action.payload
        },

        setCurrentTestDataToAddToInformationBlock: (state, action) => {
            // console.log('action.payload', action.payload)
            // console.log('typeof action.payload', typeof action.payload)
            state.currentTestDataToAddToInformationBlock = action.payload
        },

        resetCreateInformationBlockData: (state, action) => { //Commenting out resetCreateInformationBlockData
            state.newInformationBlockDoc = {
                id: '',
                type: [],
                name: '',
                description: '',
                estimatedTimeForCompletionInMinutes: 0,

                lessonsThatAreUsingThisInformationBlock: [],
                coursesThatAreUsingThisInformationBlock: [],
                informationBlocksThatAreUsingThisInformationBlock: [],

                data: '',

                collaborators: [],
                createdBy: '',
                createdOn: '',
                lastModifiedOn: '',
                lastModifiedBy: '',
                version: 1,
                isCurrent: true,
                createdNewVersionFrom: '',
                isCloned: false,
                clonedFrom: '',

                testPoolArray: [],
                requirementsArray: [],
                requirementsMapForAdditionalInformation: {},
            }

            state.newInformationBlockTestPool = []
            state.currentNewInformationBlockTestPoolIndex = 0
            state.testLogicContainer = {}
            state.refForTestMaker = null
            state.typeOfInformationBlockActionPermission = 'add'
            state.selectedRequirement = ''

            state.selectedInformationBlockTypeToUpload = ''

            state.selectedInformationBlockTypeToImport = ''

            state.informationBlockStepsVisited = {
                0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
            }
            state.addImageCurrentStep = 0

            state.retryCounter = 0
            state.requirementStatus = {}
            state.selectedInformationBlockToAddToLesson = ''

            state.selectedInformationBlockToCreateForLesson = ''
        },

        // setRetryCounter: (state, action) => { //Commenting out entire setRetryCounter
        //     console.log('Inside setRetryCounter')
        //     // state.retry_counter -= 1
        //     // console.log('RETRY_COUNTER:', state.retry_counter)
        //     // console.log('NEXT_LESSON_INDEX:', state.next_lesson_index)
        //     if (!action.payload.retryCounter) {
        //         //state.courseProgress = state.originalCourseProgress //Commenting out resetting courseProgress to original
        //         state.courseprogress = state.course_progress
        //         state.failedTest = true
        //         //state.retryCounter = 3 //Adding a reset for the retry counter
        //         console.log('Failed Test') 
        //     } else {
        //         state.courseProgress.lessonProgressMap[action.payload.lessonId].informationBlockRetryCounterMap[action.payload.informationBlockId] = action.payload.retryCounter
        //         console.log('Here the lesson progress map is set action.payload.retryCounter is ', action.payload.retryCounter)
        //     }

        //     if (action.payload.nextLessonIndex) { //Adding if to update next_lesson_index
        //         state.next_lesson_index = action.payload.nextLessonIndex 
        //         console.log('next_lesson_index set in setRetryCounter ', action.payload.nextLessonIndex)
        //     }


        //     const courseProgressRef = doc( //Commenting out seemingly useless firebase doc update block
        //         db,
        //         'courseProgress',
        //         state.courseProgress.id
        //     )

        //     const currentDocDate = serverTimestamp()

        //     if (state.courseProgress.enrolledOn) {
        //         delete state.courseProgress.enrolledOn
        //     }

        //     if (state.courseProgress.startedOn) {
        //         delete state.courseProgress.startedOn
        //     }

        //     state.courseProgress.lastModifiedOn = currentDocDate

        //     const initiateUpdate = async () => {
        //         if (state.courseProgress.maxHoursPerDay === undefined) {
        //             delete state.courseProgress.maxHoursPerDay
        //         }

        //         if (state.courseProgress.isStudyLimitProvided === undefined) {
        //             delete state.courseProgress.isStudyLimitProvided
        //         }

        //         await updateDoc(courseProgressRef, state.courseProgress)
        //     }
        //     initiateUpdate()

        // },

        setRetryCounter: (state, action) => {
            const { lessonId, informationBlockId, retryCounter } = action.payload;
            const lessonProgress = state.courseProgress.lessonProgressMap[lessonId];

            // Update the retry counter for the current information block
            if (retryCounter !== undefined) {
                lessonProgress.informationBlockRetryCounterMap[informationBlockId] = retryCounter;
                console.log('Updated retryCounter:', retryCounter);
            }

            // Handle test failure logic
            if (retryCounter === 0) {
                state.failedTest = true;
                console.log('Failed Test');

                // Reset retry counter for the failed information block only after failing 3 times
                if (lessonProgress.informationBlockRetryCounterMap[informationBlockId] === 0) {
                    // Reset retry counter for all information blocks in the current lesson
                    lessonProgress.informationBlocksArray.forEach(blockId => {
                        lessonProgress.informationBlockRetryCounterMap[blockId] = 3;
                    });

                    // Reset the progress of the current lesson
                    lessonProgress.status = 'Ongoing';
                    lessonProgress.informationBlocksArray.forEach(blockId => {
                        lessonProgress.informationBlockStatusMap[blockId] = 'Ongoing';
                    });
                }
            }

            // Update next lesson index if provided
            if (action.payload.nextLessonIndex !== undefined) {
                state.next_lesson_index = action.payload.nextLessonIndex;
                console.log('next_lesson_index set to', action.payload.nextLessonIndex);
            }
        
            const courseProgressRef = doc(db, 'courseProgress', state.courseProgress.id);
            const currentDocDate = serverTimestamp();
        
            if (state.courseProgress.enrolledOn) {
                delete state.courseProgress.enrolledOn;
            }
        
            if (state.courseProgress.startedOn) {
                delete state.courseProgress.startedOn;
            }
        
            state.courseProgress.lastModifiedOn = currentDocDate;
        
            const initiateUpdate = async () => {
                if (state.courseProgress.maxHoursPerDay === undefined) {
                    delete state.courseProgress.maxHoursPerDay;
                }
        
                if (state.courseProgress.isStudyLimitProvided === undefined) {
                    delete state.courseProgress.isStudyLimitProvided;
                }
        
                await updateDoc(courseProgressRef, state.courseProgress);
            };
            initiateUpdate();
        },

        setRetryCounterPreview: (state, action) => {
            console.log('Inside setRetryCounterPreview', action.payload)

            if (!action.payload.retryCounter) {
                console.log('Failed Test in setRetryCounterPreview')
                //state.courseProgress = state.originalCourseProgress //Commenting out state.courseProgress being reset to original
                console.log('Commenting out state.courseProgress being reset to original')

                state.failedTest = true
            } else {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockRetryCounterMap[
                    action.payload.informationBlockId
                ] = action.payload.retryCounter
                console.log('Commenting out retryCounter logic in setRetryCounterPreview')
            }

            if ( //Commenting out seemingly useless firebase doc update
                state.courseProgress.isPreview ||
                (!state.courseProgress.isPreview &&
                    state.courseProgress.status !== 'Completed')
            ) {
                const courseProgressRef = doc(
                    db,
                    'courseProgress',
                    state.courseProgress.id
                )

                const currentDocDate = serverTimestamp()

                if (state.courseProgress.enrolledOn) {
                    delete state.courseProgress.enrolledOn
                }

                if (state.courseProgress.startedOn) {
                    delete state.courseProgress.startedOn
                }

                state.courseProgress.lastModifiedOn = currentDocDate

            }
        },

        setRequirementStatus: (state, action) => { //Commenting out set requirement status
            state.requirementStatus = action.payload
        },

        setSelectedInformationBlockToCreateForLesson: (state, action) => {
            state.selectedInformationBlockToCreateForLesson = action.payload
        },

        setSavedSecondsPlayed: (state, action) => {
            state.newInformationBlockDoc.requirementsMapForAdditionalInformation.secondsPlayed =
                action.payload
        },

        removeSavedSecondsPlayed: (state, action) => {
            delete state.newInformationBlockDoc
                .requirementsMapForAdditionalInformation.secondsPlayed
        },
        setSavedSecondsPlayedNew: (state, action) => {

            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlockVideoProgressMap[
                action.payload.informationBlockId
            ] = action.payload.secondsPlayed

            console.log(
                'state.courseProgress.lessonProgressMap',
                state.courseProgress.lessonProgressMap
            )
        },

        removeSavedSecondsPlayedNew: (state, action) => {
            if (
                state.courseProgress.lessonProgressMap[action.payload.lessonId]
            ) {
                if (
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].informationBlockVideoProgressMap[
                        action.payload.informationBlockId
                    ]
                ) {
                    delete state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].informationBlockVideoProgressMap[
                        action.payload.informationBlockId
                    ]
                }
            }
        },

        setSelectedCourseId: (state, action) => { //Commenting out set blocks
            state.selectedCourseId = action.payload
        },

        setSelectedLessonId: (state, action) => {
            state.selectedLessonId = action.payload
        },

        setSelectedLessonIndex: (state, action) => {
            state.selectedLessonIndex = action.payload
        },


        setRequirementStatusNew: (state, action) => {
            //console.log('SET REQUIREMENT STATUS NEW')
            const requirementStatus = state.courseProgress.lessonProgressMap[action.payload.lessonId].informationBlockRequirementStatusMap[action.payload.informationBlockId];
            
            console.log('setting requirement status new block id: ', action.payload.informationBlockId)

            state.courseProgress.lessonProgressMap[action.payload.lessonId].informationBlockRequirementStatusMap[ //commenting out potentially buggy logic
                action.payload.informationBlockId
            ] = {
                ...state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockRequirementStatusMap[
                    action.payload.informationBlockId
                ],
                ...action.payload.requirementTemplate,
            }

            let isAllCompleted = true

            if (action.payload.isAllCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockStatusMap[action.payload.informationBlockId] =
                    'Completed'
            }

            const shouldRetakeBeCompletedMap = {}
            Object.keys(
                state.courseProgress.lessonProgressMap[action.payload.lessonId]
                    .informationBlockRequirementStatusMap[
                    action.payload.informationBlockId
                ]
            ).forEach((requirementEl) => {
                if (
                    requirementEl === 'Timer' ||
                    requirementEl === 'Point based' ||
                    requirementEl === 'Finish test' ||
                    requirementEl === 'Pop Test'
                ) {
                    shouldRetakeBeCompletedMap[`${requirementEl}`] =
                        state.courseProgress.lessonProgressMap[
                            action.payload.lessonId
                        ].informationBlockRequirementStatusMap[
                            action.payload.informationBlockId
                        ][requirementEl]
                }

                if (requirementEl === 'Retake') {
                    let shouldRetakeBeCompleted = true

                    Object.values(shouldRetakeBeCompletedMap).forEach(
                        (valueObject) => {
                            if (valueObject !== 'Completed') {
                                shouldRetakeBeCompleted = false
                            }
                        }
                    )


                    if (shouldRetakeBeCompleted) {
                        state.courseProgress.lessonProgressMap[
                            action.payload.lessonId
                        ].informationBlockRequirementStatusMap[
                            action.payload.informationBlockId
                        ]['Retake'] = 'Completed'
                    }
                }
            })

            let shouldInformationBlockBeCompleted = true
            const requirementStatusMap = state.courseProgress.lessonProgressMap[action.payload.lessonId]
            .informationBlockRequirementStatusMap[action.payload.informationBlockId];

            console.log('These values are ', requirementStatusMap);
            // if (requirementStatusMap["Point based"] === "Completed") {
            //     // Dispatch showPopup action
            //     const dispatch = useDispatch();
            //     dispatch(showPopup());
            // }
            Object.values(
                state.courseProgress.lessonProgressMap[action.payload.lessonId]
                    .informationBlockRequirementStatusMap[
                    action.payload.informationBlockId
                ]
            ).forEach((valueObject) => {
                // console.log('valueObject', valueObject)
                if (valueObject !== 'Completed') {
                    shouldInformationBlockBeCompleted = false
                }
            })

            if (shouldInformationBlockBeCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockStatusMap[action.payload.informationBlockId] =
                    'Completed'
            }

            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlocksArray.forEach((el) => {
                if (
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].informationBlockStatusMap[el] !== 'Completed'
                ) {
                    isAllCompleted = false
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].status = 'Ongoing'
                }
            })

            if (isAllCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].status = 'Completed'
            }

            if (action.payload.testResultsData) {
                state.testResultsData = action.payload.testResultsData
            }

            const courseProgressRef = doc(
                db,
                'courseProgress',
                state.courseProgress.id
            )

            const currentDocDate = serverTimestamp()

            if (state.courseProgress.enrolledOn) {
                delete state.courseProgress.enrolledOn
            }

            if (state.courseProgress.startedOn) {
                delete state.courseProgress.startedOn
            }

            state.courseProgress.lastModifiedOn = currentDocDate

            const initiateUpdate = async () => {
                console.log('Course progress being updated in firebase')
                if (state.courseProgress.maxHoursPerDay === undefined) {
                    delete state.courseProgress.maxHoursPerDay
                }

                if (state.courseProgress.isStudyLimitProvided === undefined) {
                    delete state.courseProgress.isStudyLimitProvided
                }
                await updateDoc(courseProgressRef, state.courseProgress)
            }
            initiateUpdate()
            //console.log('RIGHT BEFORE CALLING updateInformationBlockStatus')
            // action.asyncDispatch(updateInformationBlockStatus(action.payload)); //Adding logic to call the updateInformationBlockStatus
        },

        setRequirementStatusPreview: (state, action) => {
            console.log('Commenting out setRequirementStatusPreview')

            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlockRequirementStatusMap[
                action.payload.informationBlockId
            ] = {
                ...state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockRequirementStatusMap[
                    action.payload.informationBlockId
                ],
                ...action.payload.requirementTemplate,
            }

            console.log(
                'JSON - setRequirementStatusNew',
                JSON.stringify(
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].informationBlockRequirementStatusMap[
                        action.payload.informationBlockId
                    ]
                )
            )

            let isAllCompleted = true

            if (action.payload.isAllCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockStatusMap[action.payload.informationBlockId] =
                    'Completed'
            }

            const shouldRetakeBeCompletedMap = {}

            Object.keys(
                state.courseProgress.lessonProgressMap[action.payload.lessonId]
                    .informationBlockRequirementStatusMap[
                    action.payload.informationBlockId
                ]
            ).forEach((requirementEl) => {
                if (
                    requirementEl === 'Timer' ||
                    requirementEl === 'Point based' ||
                    requirementEl === 'Finish test' ||
                    requirementEl === 'Pop Test'
                ) {
                    shouldRetakeBeCompletedMap[`${requirementEl}`] =
                        state.courseProgress.lessonProgressMap[
                            action.payload.lessonId
                        ].informationBlockRequirementStatusMap[
                            action.payload.informationBlockId
                        ][requirementEl]
                }

                if (requirementEl === 'Retake') {
                    // console.log(
                    //     'shouldRetakeBeCompletedMap',
                    //     shouldRetakeBeCompletedMap
                    // )
                    let shouldRetakeBeCompleted = true

                    Object.values(shouldRetakeBeCompletedMap).forEach(
                        (valueObject) => {
                            if (valueObject !== 'Completed') {
                                // console.log('valueObject', valueObject)
                                shouldRetakeBeCompleted = false
                            }
                        }
                    )

                    // console.log('shouldRetakeBeCompleted', shouldRetakeBeCompleted)

                    if (shouldRetakeBeCompleted) {
                        state.courseProgress.lessonProgressMap[
                            action.payload.lessonId
                        ].informationBlockRequirementStatusMap[
                            action.payload.informationBlockId
                        ]['Retake'] = 'Completed'
                    }
                }
            })

            let shouldInformationBlockBeCompleted = true

            Object.values(
                state.courseProgress.lessonProgressMap[action.payload.lessonId]
                    .informationBlockRequirementStatusMap[
                    action.payload.informationBlockId
                ]
            ).forEach((valueObject) => {
                // console.log('valueObject', valueObject)
                if (valueObject !== 'Completed') {
                    shouldInformationBlockBeCompleted = false
                }
            })

            if (shouldInformationBlockBeCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].informationBlockStatusMap[action.payload.informationBlockId] =
                    'Completed'
            }

            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlocksArray.forEach((el) => {
                if (
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].informationBlockStatusMap[el] !== 'Completed'
                ) {
                    isAllCompleted = false
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].status = 'Ongoing'
                }
            })

            if (isAllCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].status = 'Completed'
            }

            if (
                state.courseProgress.isPreview ||
                (!state.courseProgress.isPreview &&
                    state.courseProgress.status !== 'Completed')
            ) {
                const courseProgressRef = doc(
                    db,
                    'courseProgress',
                    state.courseProgress.id
                )

                const currentDocDate = serverTimestamp()

                if (state.courseProgress.enrolledOn) {
                    delete state.courseProgress.enrolledOn
                }

                if (state.courseProgress.startedOn) {
                    delete state.courseProgress.startedOn
                }

                state.courseProgress.lastModifiedOn = currentDocDate

                // console.log('JSON.stringify(state.courseProgress)-setRequirementStatusNew', JSON.stringify(state.courseProgress))
            }

            if (action.payload.testResultsData) {
                state.testResultsData = action.payload.testResultsData
            }
        },

        setInformationBlockTestData: (state, action) => { 
            //console.log('Commenting out setInformationBlockTestData')
            state.requirementStatus = action.payload.requirementStatus

            state.newInformationBlockDoc = action.payload.informationBlockDoc

            state.currentTestDataToAddToInformationBlock =
                state.informationBlockMapForDetails[
                    action.payload.testData
                ].data

            state.informationForCurrentTestToTake.data =
                state.informationBlockMapForDetails[
                    action.payload.testData
                ].data

            state.informationForCurrentTestToTake.surveyBlockId =
                action.payload.testData

            state.informationForCurrentTestToTake.lessonId =
                action.payload.lessonId

            state.informationForCurrentTestToTake.informationBlockId =
                action.payload.informationBlockId

            state.informationForCurrentTestToTake.informationBlockDoc =
                action.payload.informationBlockDoc
        },

        setRequirementToCompleteStatus: (state, action) => {
            console.log('Commenting out setRequirementToCompleteStatus')
            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlockStatusMap[action.payload.informationBlockId] =
                'Completed'

            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].status = 'Completed'
        },

        setNextLesson: (state, action) => {
            console.log('setNextLesson HERE')
            if (action.payload) { //Commenting out setNextLesson if logic
                console.log('inside action payload but nothing done')
                // console.log('payload?: ', action.payload.indexOfLessonArray)
                // if (action.payload.indexOfLessonArray) { //comenting out original 
                //     console.log('index of lesson array called')
                //     state.courseProgress.currentLessonIndex =
                //         action.payload.indexOfLessonArray
                // }
                if (action.payload.indexOfLessonArray != undefined && action.payload.indexOfLessonArray >= 0) {
                    console.log('index of lesson array called')
                    state.courseProgress.currentLessonIndex =
                        action.payload.indexOfLessonArray
                }
            } else {
                console.log('increment')
                state.courseProgress.currentLessonIndex += 1
            }
            // Update highestLessonReached if currentLessonIndex exceeds it
            const currentLessonIndex = state.courseProgress.currentLessonIndex;
            if (currentLessonIndex > state.courseProgress.highestLessonReached) {
                state.courseProgress.highestLessonReached = currentLessonIndex;
            }

            if (
                state.courseProgress.lessonsArray[
                    state.courseProgress.currentLessonIndex
                ]
            ) {
                console.log('current lesson updated')
                state.courseProgress.currentLesson =
                    state.courseProgress.lessonsArray[
                        state.courseProgress.currentLessonIndex
                    ]
            } else {
                console.log('current lesson updated in a different way')
                state.courseProgress.currentLesson =
                    state.courseProgress.lessonsArray[
                        state.courseProgress.lessonsArray.length
                    ]
            }

            if ( //Commenting out seemingly useless firebase doc update
                state.courseProgress.isPreview ||
                (!state.courseProgress.isPreview &&
                    state.courseProgress.status !== 'Completed')
            ) {
                const courseProgressRef = doc(
                    db,
                    'courseProgress',
                    state.courseProgress.id
                )

                const currentDocDate = serverTimestamp()

                if (state.courseProgress.enrolledOn) {
                    delete state.courseProgress.enrolledOn
                }

                if (state.courseProgress.startedOn) {
                    delete state.courseProgress.startedOn
                }

                state.courseProgress.lastModifiedOn = currentDocDate

                const initiateUpdate = async () => {
                    if (state.courseProgress.maxHoursPerDay === undefined) {
                        delete state.courseProgress.maxHoursPerDay
                    }

                    if (
                        state.courseProgress.isStudyLimitProvided === undefined
                    ) {
                        delete state.courseProgress.isStudyLimitProvided
                    }

                    await updateDoc(courseProgressRef, state.courseProgress)
                }
                initiateUpdate()
            }
        },

        setNextLessonPreview: (state, action) => {
            console.log('Commenting out setNextLessonPreview')
            state.courseProgress.currentLessonIndex += 1

            if (
                state.courseProgress.lessonsArray[
                    state.courseProgress.currentLessonIndex
                ]
            ) {
                state.courseProgress.currentLesson =
                    state.courseProgress.lessonsArray[
                        state.courseProgress.currentLessonIndex
                    ]
            } else {
                state.courseProgress.currentLesson =
                    state.courseProgress.lessonsArray[
                        state.courseProgress.lessonsArray.length
                    ]
            }

            if (
                state.courseProgress.isPreview ||
                (!state.courseProgress.isPreview &&
                    state.courseProgress.status !== 'Completed')
            ) {
                const courseProgressRef = doc(
                    db,
                    'courseProgress',
                    state.courseProgress.id
                )

                const currentDocDate = serverTimestamp()

                if (state.courseProgress.enrolledOn) {
                    delete state.courseProgress.enrolledOn
                }

                if (state.courseProgress.startedOn) {
                    delete state.courseProgress.startedOn
                }

                state.courseProgress.lastModifiedOn = currentDocDate
            }
        },

        setBackLesson: (state, action) => {
            state.courseProgress.currentLessonIndex -= 1

            state.courseProgress.currentLesson =
                state.courseProgress.lessonsArray[
                    state.courseProgress.currentLessonIndex
                ]
        },

        setImageForNoteTakingDataURL: (state, action) => {
            state.imageForNoteTakingDataURL = action.payload
        },

        setSpecificLesson: (state, action) => {
            console.log('Setting specific lesson')
            const lessonIndex = state.courseProgress.lessonsArray.findIndex(
                (el) => el === action.payload
            )

            state.courseProgress.currentLessonIndex = lessonIndex

            if (state.courseProgress.lessonsArray[lessonIndex]) {
                state.courseProgress.currentLesson =
                    state.courseProgress.lessonsArray[lessonIndex]
            } else {
                state.courseProgress.currentLesson =
                    state.courseProgress.lessonsArray[
                        state.courseProgress.lessonsArray.length
                    ]
            }
        },

        setCompleteInformationStatus: (state, action) => {
            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlockStatusMap[action.payload.informationBlockId] =
                'Completed'

            let isAllCompleted = true

            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].informationBlocksArray.forEach((el) => {
                if (
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].informationBlockStatusMap[el] !== 'Completed'
                ) {
                    isAllCompleted = false
                    state.courseProgress.lessonProgressMap[
                        action.payload.lessonId
                    ].status = 'Ongoing'
                }
            })

            if (isAllCompleted) {
                state.courseProgress.lessonProgressMap[
                    action.payload.lessonId
                ].status = 'Completed'
            }
        },

        setTextForNotes: (state, action) => {
            state.descriptionForCurrentTextNote = action.payload

            state.informationForCurrentTextNote.description =
                action.payload.description

            state.informationForCurrentTextNote.lessonId =
                action.payload.lessonId

            state.informationForCurrentTextNote.informationBlockId =
                action.payload.informationBlockId
        },

        saveNotes: (state, action) => {
            state.courseProgress.lessonProgressMap[
                action.payload.lessonId
            ].textNotes = action.payload.description
        },

        setTestStatus: (state, action) => {
            state.failedTest = action.payload.failedTest
        },

        //Commenting out entire setCourseDetails
        // setCourseDetails: (state, action) => { //has payload courseProgress, informationBlockMapForDetails, lessonMapForDetails, navigate, and originalCourseProgress
        //     console.log('Inside setCourseDetails') //Is used to set initial state values

        //     state.lessonMapForDetails = action.payload.lessonMapForDetails

        //     state.informationBlockMapForDetails =
        //         action.payload.informationBlockMapForDetails

        //     state.courseProgress = action.payload.courseProgress
        //     state.course_progress = action.payload.courseProgress //Adding our own variable to track courseProgress
        //     console.log('Tracked course progress ', state.course_progress)

        //     if (action.payload.originalCourseProgress) {
        //         console.log('Original Course Progress set', JSON.stringify(action.payload.courseProgress))
        //         const copyOfLessonProgress = JSON.parse(
        //             JSON.stringify(action.payload.courseProgress) //Set entire courseProgressObject
        //         )

        //         copyOfLessonProgress.lessonProgressMap =
        //             action.payload.originalCourseProgress.lessonProgressMap //Set the lessonProgressMap
        //         console.log('Setting current lesson in setCourseDetails')
        //         // if (state.courseProgress.currentLesson != undefined) { //Addding logic for currentLesson
        //         //     copyOfLessonProgress.currentLesson = state.courseProgress.currentLesson
        //         //     console.log('Current state lesson is ', state.courseProgress.currentLesson)
        //         // } else {
        //         //     console.log('Current state lesson is undefined so set to lessonsArray[0]')
        //         //     copyOfLessonProgress.currentLesson = action.payload.courseProgress.lessonsArray[0]
        //         // }
                
        //         copyOfLessonProgress.currentLesson = //Commenting out copyOfLessonProgress
        //             action.payload.courseProgress.lessonsArray[0] //Set the current lesson ID

        //         // if (state.next_lesson_index != undefined) { //Adding copyOfLessonProgress logic
        //         //     console.log('Current state lesson index is ', state.next_lesson_index)
        //         //     copyOfLessonProgress.currentLessonIndex = state.next_lesson_index
        //         // } else {
        //         //     console.log('Current state lesson index is set to 0 because not set yet')
        //         //     copyOfLessonProgress.currentLessonIndex = 0
        //         // }
        //         copyOfLessonProgress.currentLessonIndex = 0 //Set current lesson index

        //         state.originalCourseProgress = copyOfLessonProgress

        //     } else {
        //         state.originalCourseProgress = action.payload.courseProgress
        //     }
        // },

        setCourseDetails: (state, action) => { //commenting out potentially buggy set course details
            console.log('Inside setCourseDetails');
            state.lessonMapForDetails = action.payload.lessonMapForDetails;
            state.informationBlockMapForDetails = action.payload.informationBlockMapForDetails;
            state.courseProgress = action.payload.courseProgress;
            state.course_progress = action.payload.courseProgress;
        
            if (action.payload.originalCourseProgress) {
                const copyOfLessonProgress = JSON.parse(JSON.stringify(action.payload.courseProgress));
                copyOfLessonProgress.lessonProgressMap = action.payload.originalCourseProgress.lessonProgressMap;
                copyOfLessonProgress.currentLesson = action.payload.courseProgress.lessonsArray[0];
                copyOfLessonProgress.currentLessonIndex = 0;
                state.originalCourseProgress = copyOfLessonProgress;
            } else {
                state.originalCourseProgress = action.payload.courseProgress;
            }
        },
        

        setCourseProgressToComplete: (state, action) => {
            if (state.courseProgress.status === 'Ongoing') {
                state.courseProgress.status = 'Completed'

                const courseProgressRef = doc(
                    db,
                    'courseProgress',
                    state.courseProgress.id
                )

                const currentDocDate = serverTimestamp()

                if (state.courseProgress.enrolledOn) {
                    delete state.courseProgress.enrolledOn
                }

                if (state.courseProgress.startedOn) {
                    delete state.courseProgress.startedOn
                }

                state.courseProgress.lastModifiedOn = currentDocDate

                state.courseProgress.completedOn = currentDocDate

                const initiateUpdate = async () => {
                    if (state.courseProgress.maxHoursPerDay === undefined) {
                        delete state.courseProgress.maxHoursPerDay
                    }

                    if (
                        state.courseProgress.isStudyLimitProvided === undefined
                    ) {
                        delete state.courseProgress.isStudyLimitProvided
                    }

                    await updateDoc(courseProgressRef, state.courseProgress)
                }
                initiateUpdate()

            }
        },
    },
    extraReducers: (builder) => { //Adding logic for update current state based on updateInformationBlockStatusThunk
        builder
            .addCase(updateInformationBlockStatus.fulfilled, (state, action) => {
                if (action.payload) {
                    const { lessonId, informationBlockId, requirementsArray, isAllCompleted } = action.payload;

                    const currentInformationBlockRequirementsStatusMap = state.courseProgress.lessonProgressMap[lessonId].informationBlockRequirementStatusMap[informationBlockId];
                    const currentInformationBlockRequirementsStatusMapLength = Object.keys(currentInformationBlockRequirementsStatusMap).length;

                    const requirementsArrayLength = Object.keys(requirementsArray).length;
                    // console.log('Current length of map ', JSON.stringify(currentInformationBlockRequirementsStatusMap))
                    // console.log('Current length of array ', JSON.stringify(requirementsArray))

                    if (requirementsArrayLength != currentInformationBlockRequirementsStatusMapLength) {
                        console.log('Requirements array and current info block differ')
                        // New array of requirements
                        const requirementsArraySet = new Set(requirementsArray);
                        console.log('1.0')
                        // Filter the map to only include keys present in the array
                        const filteredMap = Object.keys(currentInformationBlockRequirementsStatusMap)
                            .filter(key => requirementsArraySet.has(key))
                            .reduce((obj, key) => {
                                obj[key] = currentInformationBlockRequirementsStatusMap[key];
                                return obj;
                            }, {});
                        console.log('2.0')
                        // Add any missing keys from the array to the map with a default value (e.g., "Not Started")
                        requirementsArray.forEach(key => {
                            if (!filteredMap.hasOwnProperty(key)) {
                                if (key == 'Screen') {
                                    filteredMap[key] = "Completed";
                                } else {
                                    filteredMap[key] = "Ongoing";    
                                }
                            }
                        });
                        console.log('3.0')

                        // Assign the filtered and updated map back to the state
                        console.log('Previous map was ', state.courseProgress.lessonProgressMap[lessonId].informationBlockRequirementStatusMap[informationBlockId])
                        state.courseProgress.lessonProgressMap[lessonId].informationBlockRequirementStatusMap[informationBlockId] = filteredMap;
                        console.log('new map is ', filteredMap)

                    }
                }   
            })
    },
})

const checkAndUpdateLessonStatus = (state, lessonId) => {
    console.log('Inside checkAndUpdateLessonStatus')
    const lesson = state.courseProgress.lessonProgressMap[lessonId];
    const allBlocksCompleted = lesson.informationBlocksArray.every(blockId => {
        return lesson.informationBlockStatusMap[blockId] === 'Completed';
    });
    if (allBlocksCompleted) {
        lesson.status = 'Completed';
    }
};

export const {
    addNewInformationBlockData,
    addDataToTestLogicContainer,
    setRefForTestMaker,

    addNewInformationBlockTestRequirements,
    setSelectedRequirement,
    setSelectedInformationBlockToAddToLesson,
    setSelectedInformationBlockStepsVisited,
    setTypeOfInformationBlockActionPermission,

    setSelectedInformationBlockTypeToUpload,
    setSelectedInformationBlockTypeToImport,
    resetCreateInformationBlockData,

    setCurrentTestDataToAddToInformationBlock,

    setRetryCounter,
    setRequirementStatus,

    selectedInformationBlockToCreateForLesson,
    setSelectedInformationBlockToCreateForLesson,

    setSavedSecondsPlayed,
    removeSavedSecondsPlayed,

    setRequirementStatusNew,
    setInformationBlockTestData,

    setRequirementToCompleteStatus,
    setNextLesson,

    setBackLesson,

    setSavedSecondsPlayedNew,
    removeSavedSecondsPlayedNew,
    setImageForNoteTakingDataURL,
    setCompleteLesson,
    setCompleteInformationStatus,
    setTextForNotes,
    saveNotes,
    setSpecificLesson,
    setCourseDetails,
    setRequirementStatusPreview,
    setNextLessonPreview,
    setRetryCounterPreview,
    setTestStatus,
    setCourseProgressToComplete,
} = firebaseSlice.actions

export const setRequirementStatusNewAndUpdate = (payload) => async (dispatch, getState) => {
    //dispatch(setRequirementStatusNew(payload));
    console.log('The payload in setRequirementStatusNew is ', payload)
    await dispatch(updateInformationBlockStatus(payload));
};

export default firebaseSlice.reducer
