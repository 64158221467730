import { createSlice } from '@reduxjs/toolkit'

export const initialUpdatedDataForSurveyBlockData = {
    requirementsMapForAdditionalSurvey: {}
}

const stateSlice = createSlice({
    name: 'lessons/state',
    initialState: {
        deleteConfirmation: false,
        selectedSurveyBlock: '',
        selectedLesson: '',
        lessonDialog: false,

        updatedDataForSurveyBlock: initialUpdatedDataForSurveyBlockData,

        typeOfBlockCreation: '',
        tabToReturnTo: '',

        lessonPreviewData: {},
    },
    reducers: {
        setLessonPreviewData: (state, action) => {
            state.lessonPreviewData = action.payload.lessonPreviewData

            state.updatedDataForSurveyBlock = {
                ...state.updatedDataForSurveyBlock,
                ...action.payload.updatedDataForSurveyBlock
            }
        },

        setTabToReturnTo: (state, action) => {
            state.tabToReturnTo = action.payload
        },
        setTypeOfBlockCreation: (state, action) => {
            state.typeOfBlockCreation = action.payload
        },
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedSurveyBlock: (state, action) => {
            state.selectedSurveyBlock = action.payload
        },
        setSelectedLesson: (state, action) => {
            state.selectedLesson = action.payload
        },
        toggleSurveyBlockDialog: (state, action) => {
            state.lessonDialog = action.payload
        },
        setUpdatedDataForSurveyBlock: (state, action) => {
            // console.log('action.payload', action.payload)

            // console.log('looook', {
            //     ...state.updatedDataForSurveyBlock,
            //     ...action.payload
            // })
            state.updatedDataForSurveyBlock = {
            ...state.updatedDataForSurveyBlock,
            ...action.payload
        }
        },
        resetUpdatedDataForSurveyBlock: (state, action) => {
            state.updatedDataForSurveyBlock = initialUpdatedDataForSurveyBlockData
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedSurveyBlock, setSelectedLesson, toggleSurveyBlockDialog, setUpdatedDataForSurveyBlock, resetUpdatedDataForSurveyBlock, setTypeOfBlockCreation, setTabToReturnTo, setLessonPreviewData } =
    stateSlice.actions

export default stateSlice.reducer
