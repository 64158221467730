import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import {
//     apiGetSalesUsers,
//     apiDeleteSalesUsers,
// } from 'services/SalesService'
import wildCardSearch from 'utils/wildCardSearch'
import sortBy from 'utils/sortBy'
import paginate from 'utils/paginate'

// export const getUsers = createAsyncThunk(
//     'resultManager/data/getUsers',
//     async (data) => {
//         // const response = await apiGetSalesUsers(data)
//         // const body = JSON.parse(requestBody)
//         const { pageIndex, pageSize, sort, query, firebaseListData } = data
//         const { order, key } = sort
//         const products = firebaseListData
//         const sanitizeUsers = products.filter(
//             (elm) => typeof elm !== 'function'
//         )
//         let data = sanitizeUsers
//         let total = products.length

//         if ((key === 'category' || key === 'name') && order) {
//             data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
//         } else {
//             data.sort(sortBy(key, order === 'desc', parseInt))
//         }

//         if (query) {
//             data = wildCardSearch(data, query)
//             total = data.length
//         }

//         data = paginate(data, pageSize, pageIndex)

//         const responseData = {
//             data: data,
//             total: total,
//         }

//         return responseData.data
//     }
// )

// export const deleteUser = async (data) => {
//     // const response = await apiDeleteSalesUsers(data)
//     const { id } = data
//     schema.db.productsData.remove({ id })
//     return response.data
// }

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}

export const initialFilterData = {
    careerProgramList: [],
    industryList: [],
    status: ['Draft', 'Live'],
}

const dataSlice = createSlice({
    name: 'surveyBlockResults/data',
    initialState: {
        firebaseUsersList: [],
        loading: false,
        userList: [],
        tableData: initialTableData,
        filterData: initialFilterData,
        initialTableLoad: true,
        initialFilterLoad: true,
        originalFilterData: []
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFirebaseUsersList: (state, action) => {
            state.firebaseUsersList = action.payload
        },
        updateUserList: (state, action) => {
            state.userList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload

            if (state.initialFilterLoad) {
                state.originalFilterData = action.payload
                state.initialFilterLoad = false    
            }
        },
        getUsers: (state, action) => {

            const { pageIndex, pageSize, sort, query, firebaseListData } = action.payload

            if (firebaseListData) {
                state.firebaseUsersList = action.payload.firebaseListData
            }

            const { order, key } = sort

            const products = firebaseListData ||  state.firebaseUsersList

            const sanitizeUsers = products.filter(
                (elm) => {
                    if (typeof elm !== 'function') {

                        if (state.initialTableLoad) {
                           return true
                        } else {
                            let matchesFilteredCareerPrograms = false

                        
                            elm.careerProgramsThatAreUsingTheUser.forEach(filteredProgram => {
    
                                if (state.filterData.careerProgramList.includes(filteredProgram)) {
                                    matchesFilteredCareerPrograms = true
                                }
                                
                            });
    
                            let matchesFilteredIndustries = false
    
                            
                            elm.industriesThatAreUsingTheUser.forEach(filteredIndustry => {
    
                                if (state.filterData.industryList.includes(filteredIndustry)) {
                                    matchesFilteredIndustries = true
                                }
                                
                            });
    
                            
                            let matchesFilteredStatus = state.filterData.status.includes(elm.status)
    
                            return matchesFilteredStatus || matchesFilteredIndustries || matchesFilteredCareerPrograms
                        }

                       
                    } else {
                        return false
                    }
                }
            )
            let data = sanitizeUsers
            let total = products.length
    
            if ((key === 'name' ||
            key === 'createdBy_table' ||
            key === 'industriesThatAreUsingTheUser_table' ||
            key === 'careerProgramsThatAreUsingTheUser_table' ||
            key === 'isInPerson_table' ||
            key === 'isCertificationProvided_table' ||
            key === 'status' ) && order) {
                data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
            } else {
                data.sort(sortBy(key, order === 'desc', parseInt))
            }
    
            if (query) {
                data = wildCardSearch(data, query)
                total = data.length
            }
    
            data = paginate(data, pageSize, pageIndex)

            state.userList = data
            state.tableData.total = total
            state.loading = false
            state.initialTableLoad = false
        }
    },
    // extraReducers: {
    //     [getUsers.fulfilled]: (state, action) => {
    //         state.userList = action.payload.data
    //         state.tableData.total = action.payload.total
    //         state.loading = false
    //     },
    //     [getUsers.pending]: (state) => {
    //         state.loading = true
    //     },
    // },
})

export const {
    updateUserList,
    setTableData,
    setFilterData,
    setFirebaseUsersList,
    getUsers,
    setLoading
} = dataSlice.actions

export default dataSlice.reducer
