// import { diff } from 'deep-diff';

// const loggerMiddleware = (storeAPI) => (next) => (action) => {
//     console.log('Dispatching action:', action);

//     const prevState = storeAPI.getState();
//     const prevCourseProgress = prevState.firebase.courseProgress;

//     const stackTrace = new Error().stack;
//     console.log('Stack Trace:', stackTrace);

//     let result = next(action);

//     const nextState = storeAPI.getState();
    
//     const nextCourseProgress = nextState.firebase.courseProgress;
//     const differences = diff(prevState, nextState);

//     if (differences) {
//         console.log('Differences in states:', differences);
//     } else {
//         console.log('No differences in states.');
//     }

//     // Find the differences in courseProgress specifically
//     const courseProgressDifferences = diff(prevCourseProgress, nextCourseProgress);
//     if (courseProgressDifferences) {
//         console.log('Differences in courseProgress:', courseProgressDifferences);
//     } else {
//         console.log('No differences in courseProgress.');
//     }

//     return result;
// };

// export default loggerMiddleware;


import { diff } from 'deep-diff';

const loggerMiddleware = (storeAPI) => (next) => (action) => {
    console.log('Dispatching action:', action);

    const prevState = storeAPI.getState();
    const prevCourseProgress = prevState.firebase.courseProgress;

    let result = next(action);

    const nextState = storeAPI.getState();
    const nextCourseProgress = nextState.firebase.courseProgress;
    const differences = diff(prevState, nextState);

    if (differences) {
        console.log('Differences in states:', differences);
    } else {
        console.log('No differences in states.');
    }

    const courseProgressDifferences = diff(prevCourseProgress, nextCourseProgress);
    if (courseProgressDifferences) {
        console.log('Differences in courseProgress:', courseProgressDifferences);
    } else {
        console.log('No differences in courseProgress.');
    }

    return result;
};

export default loggerMiddleware;
