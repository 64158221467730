// actions.js (or wherever you define your actions)
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export const showPopup = () => ({
    type: SHOW_POPUP,
});

export const hidePopup = () => ({
    type: HIDE_POPUP,
});

const initialState = {
    popupVisible: false,
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_POPUP:
            return { ...state, popupVisible: true };
        case HIDE_POPUP:
            return { ...state, popupVisible: false };
        default:
            return state;
    }
};

export default uiReducer;
