import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'ticketsManager/state',
    initialState: {
        deleteConfirmation: false,
        selectedTicket: '',
        selectedLesson: '',

        selectedDashboardTemplate: '',
        selectedTicketDetails: {},
        
        originalDashboardConfiguration: {},
        defaultDashboardConfiguration: {},
                deleteConfirmationTemplate: false,
        editConfirmationTemplate: false,

        defaultDashboardConfigurationId: '',

        selectedRowData: {id: null}
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleDeleteConfirmationTemplate: (state, action) => {
            state.deleteConfirmationTemplate = action.payload
        },

        toggleEditConfirmationTemplate: (state, action) => {
            state.editConfirmationTemplate = action.payload
        },

        setSelectedTicket: (state, action) => {
            state.selectedTicket = action.payload
        },
        setSelectedLesson: (state, action) => {
            state.selectedLesson = action.payload
        },

        setSelectedTicketDetails: (state, action) => {
            state.selectedTicketDetails = action.payload
        },
        setSelectedDashboardTemplate: (state, action) => {
            state.selectedDashboardTemplate = action.payload
        },

        setOriginalDashboardConfiguration: (state, action) => {
            state.originalDashboardConfiguration = action.payload
        },

        setDefaultDashboardConfiguration: (state, action) => {
            state.defaultDashboardConfiguration = action.payload
        },

        setDefaultDashboardConfigurationId: (state, action) => {
            state.defaultDashboardConfigurationId = action.payload
        },

        setSelectedRowData: (state, action) => {
            state.selectedRowData = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedTicket, setSelectedLesson, setSelectedDashboardTemplate, selectedTicketDetails, setOriginalDashboardConfiguration, setDefaultDashboardConfiguration, toggleDeleteConfirmationTemplate, setDefaultDashboardConfigurationId, toggleEditConfirmationTemplate, setSelectedRowData  } =
    stateSlice.actions

export default stateSlice.reducer
