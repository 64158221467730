import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import {
//     apiGetSalesInformationBlocks,
//     apiDeleteSalesInformationBlocks,
// } from 'services/SalesService'
import wildCardSearch from 'utils/wildCardSearch'
import sortBy from 'utils/sortBy'
import paginate from 'utils/paginate'

// export const getInformationBlocks = createAsyncThunk(
//     'informationBlocks/data/getInformationBlocks',
//     async (data) => {
//         // const response = await apiGetSalesInformationBlocks(data)
//         // const body = JSON.parse(requestBody)
//         const { pageIndex, pageSize, sort, query, firebaseListData } = data
//         const { order, key } = sort
//         const products = firebaseListData
//         const sanitizeInformationBlocks = products.filter(
//             (elm) => typeof elm !== 'function'
//         )
//         let data = sanitizeInformationBlocks
//         let total = products.length

//         if ((key === 'category' || key === 'name') && order) {
//             data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
//         } else {
//             data.sort(sortBy(key, order === 'desc', parseInt))
//         }

//         if (query) {
//             data = wildCardSearch(data, query)
//             total = data.length
//         }

//         data = paginate(data, pageSize, pageIndex)

//         const responseData = {
//             data: data,
//             total: total,
//         }

//         return responseData.data
//     }
// )

// export const deleteInformationBlock = async (data) => {
//     // const response = await apiDeleteSalesInformationBlocks(data)
//     const { id } = data
//     schema.db.productsData.remove({ id })
//     return response.data
// }

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}

export const initialFilterData = {
    type: ['Image', 'Video', 'Word', 'PDF', 'Excel', 'PowerPoint'],
    status: ['Useable', 'Not useable'],
}

const dataSlice = createSlice({
    name: 'informationBlocks/data',
    initialState: {
        firebaseInformationBlocksList: [],
        loading: false,
        informationBlockList: [],
        tableData: initialTableData,
        filterData: initialFilterData,
        initialTableLoad: true,
        initialFilterLoad: true,
        originalFilterData: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFirebaseInformationBlocksList: (state, action) => {
            state.firebaseInformationBlocksList = action.payload
        },
        updateInformationBlockList: (state, action) => {
            state.informationBlockList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload

            if (state.initialFilterLoad) {
                state.originalFilterData = action.payload
                state.initialFilterLoad = false
            }
        },
        getInformationBlocks: (state, action) => {
            const { pageIndex, pageSize, sort, query, firebaseListData } =
                action.payload

            if (firebaseListData) {
                state.firebaseInformationBlocksList =
                    action.payload.firebaseListData
            }

            const { order, key } = sort

            const products =
                firebaseListData || state.firebaseInformationBlocksList

            const sanitizeInformationBlocks = products.filter((elm) => {
                if (typeof elm !== 'function') {
                    if (state.initialTableLoad) {
                        return true
                    } else {
                        let matchesFilteredType =
                            state.filterData.type.includes(elm.type)


                        let matchesFilteredStatus = state.filterData.status.includes(elm.status)
    
                        return  matchesFilteredType || matchesFilteredStatus
                    }
                } else {
                    return false
                }
            })
            let data = sanitizeInformationBlocks
            let total = products.length

            if (
                (key === 'name' || key === 'type' || key === 'description') &&
                order
            ) {
                data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
            } else {
                data.sort(sortBy(key, order === 'desc', parseInt))
            }

            if (query) {
                data = wildCardSearch(data, query)
                total = data.length
            }

            data = paginate(data, pageSize, pageIndex)

            state.informationBlockList = data
            state.tableData.total = total
            state.loading = false
            state.initialTableLoad = false
        },
    },
})

export const {
    updateInformationBlockList,
    setTableData,
    setFilterData,
    setFirebaseInformationBlocksList,
    getInformationBlocks,
    setLoading
} = dataSlice.actions

export default dataSlice.reducer
