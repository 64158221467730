
const development = false

const DEVELOPMENT_API_HOST = 'http://localhost:5000'; //'https://slaterserver.herokuapp.com'; //Commenting out original url
const PRODUCTION_API_HOST = 'https://slater-server.vercel.app'//'https://slatercertified-fe19f.web.app/'; //'https://slaterserver.herokuapp.com'

const DEVELOPMENT_CLIENT_HOST = 'http://localhost:3000'; //Adding client side prefix so server knows where to redirect to
const PRODUCTION_CLIENT_HOST = 'https://slatercertified.com'; //'https://slatercertified-fe19f.web.app';

const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/course-marketplace',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    serverApiPrefix: development ? DEVELOPMENT_API_HOST : PRODUCTION_API_HOST,
    clientApiPrefix: development ? DEVELOPMENT_CLIENT_HOST : PRODUCTION_CLIENT_HOST,
}

export default appConfig
