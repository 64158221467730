import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import {
//     apiGetSalesLessons,
//     apiDeleteSalesLessons,
// } from 'services/SalesService'
import wildCardSearch from 'utils/wildCardSearch'
import sortBy from 'utils/sortBy'
import paginate from 'utils/paginate'

// export const getLessons = createAsyncThunk(
//     'lessons/data/getLessons',
//     async (data) => {
//         // const response = await apiGetSalesLessons(data)
//         // const body = JSON.parse(requestBody)
//         const { pageIndex, pageSize, sort, query, firebaseListData } = data
//         const { order, key } = sort
//         const products = firebaseListData
//         const sanitizeLessons = products.filter(
//             (elm) => typeof elm !== 'function'
//         )
//         let data = sanitizeLessons
//         let total = products.length

//         if ((key === 'category' || key === 'name') && order) {
//             data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
//         } else {
//             data.sort(sortBy(key, order === 'desc', parseInt))
//         }

//         if (query) {
//             data = wildCardSearch(data, query)
//             total = data.length
//         }

//         data = paginate(data, pageSize, pageIndex)

//         const responseData = {
//             data: data,
//             total: total,
//         }

//         return responseData.data
//     }
// )

// export const deleteSurveyBlock = async (data) => {
//     // const response = await apiDeleteSalesLessons(data)
//     const { id } = data
//     schema.db.productsData.remove({ id })
//     return response.data
// }

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}

export const initialFilterData = {
    // type: ['Image', 'Video', 'Word', 'PDF', 'Excel', 'PowerPoint'],
    // status: ['Useable', 'Not useable'],
}

const dataSlice = createSlice({
    name: 'lessons/data',
    initialState: {
        firebaseLessonsList: [],
        loading: false,
        lessonList: [],
        tableData: initialTableData,
        filterData: initialFilterData,
        initialTableLoad: true,
        initialFilterLoad: true,
        originalFilterData: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFirebaseLessonsList: (state, action) => {
            state.firebaseLessonsList = action.payload
        },
        updateSurveyBlockList: (state, action) => {
            state.lessonList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload

            if (state.initialFilterLoad) {
                state.originalFilterData = action.payload
                state.initialFilterLoad = false
            }
        },
        getLessons: (state, action) => {
            const { pageIndex, pageSize, sort, query, firebaseListData } =
                action.payload

            if (firebaseListData) {
                state.firebaseLessonsList =
                    action.payload.firebaseListData
            }

            const { order, key } = sort

            const products =
                firebaseListData || state.firebaseLessonsList

            const sanitizeLessons = products.filter((elm) => {
                if (typeof elm !== 'function') {
                    return true
                } else {
                    return false
                }
            })
            let data = sanitizeLessons
            let total = products.length

            if (
                (key === 'name' || key === 'description') &&
                order
            ) {
                data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
            } else {
                data.sort(sortBy(key, order === 'desc', parseInt))
            }

            if (query) {
                data = wildCardSearch(data, query)
                total = data.length
            }

            data = paginate(data, pageSize, pageIndex)

            state.lessonList = data
            state.tableData.total = total
            state.loading = false
            state.initialTableLoad = false
        },
    },
    // extraReducers: {
    //     [getLessons.fulfilled]: (state, action) => {
    //         state.lessonList = action.payload.data
    //         state.tableData.total = action.payload.total
    //         state.loading = false
    //     },
    //     [getLessons.pending]: (state) => {
    //         state.loading = true
    //     },
    // },
})

export const {
    updateSurveyBlockList,
    setTableData,
    setFilterData,
    setFirebaseLessonsList,
    getLessons,
    setLoading
} = dataSlice.actions

export default dataSlice.reducer
