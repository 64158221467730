import { createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'configs/firebase.config'; 
import { setRequirementStatusNew } from './firebaseSlice'; 

const fetchInformationBlockFromFirebase = async (lessonId, informationBlockId) => {
    const docRef = doc(db, 'informationBlocks', informationBlockId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log('Doc exists ', docSnap.data())
        return docSnap.data();
    } else {
        console.error('No such document!');
        return null;
    }
};

export const updateInformationBlockStatus = createAsyncThunk(
    'firebase/updateInformationBlockStatus',
    async ({ lessonId, informationBlockId }, { getState, dispatch }) => {
        console.log('updateInformationBlockStatus informationBlockId: ', informationBlockId)
        const state = getState().firebase;

        const fetchedData = await fetchInformationBlockFromFirebase(lessonId, informationBlockId);

        console.log('fetching information block from firebase: ', fetchedData)
        if (!fetchedData) return;

        const fetchedRequirementsArray = fetchedData.requirementsArray;

        return {
            lessonId,
            informationBlockId,
            requirementsArray: fetchedRequirementsArray,
            isAllCompleted: false // Adjust this based on your logic
        };
    }
);
