import { createSlice } from '@reduxjs/toolkit'

export const initialUpdatedDataForSurveyBlockData = {
    requirementsMapForAdditionalSurvey: {}
}

const stateSlice = createSlice({
    name: 'surveyBlocks/state',
    initialState: {
        deleteConfirmation: false,
        selectedSurveyBlock: '',
        selectedLesson: '',
        surveyBlockDialog: false,

        updatedDataForSurveyBlock: initialUpdatedDataForSurveyBlockData,

        typeOfBlockCreation: '',
        tabToReturnTo: '',
    },
    reducers: {
        setTabToReturnTo: (state, action) => {
            state.tabToReturnTo = action.payload
        },
        setTypeOfBlockCreation: (state, action) => {
            state.typeOfBlockCreation = action.payload
        },
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedSurveyBlock: (state, action) => {
            state.selectedSurveyBlock = action.payload
        },
        setSelectedLesson: (state, action) => {
            state.selectedLesson = action.payload
        },
        toggleSurveyBlockDialog: (state, action) => {
            state.surveyBlockDialog = action.payload
        },
        setUpdatedDataForSurveyBlock: (state, action) => {
            // console.log('action.payload', action.payload)

            // console.log('looook', {
            //     ...state.updatedDataForSurveyBlock,
            //     ...action.payload
            // })
            state.updatedDataForSurveyBlock = {
            ...state.updatedDataForSurveyBlock,
            ...action.payload
        }
        },
        resetUpdatedDataForSurveyBlock: (state, action) => {
            state.updatedDataForSurveyBlock = initialUpdatedDataForSurveyBlockData
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedSurveyBlock, setSelectedLesson, toggleSurveyBlockDialog, setUpdatedDataForSurveyBlock, resetUpdatedDataForSurveyBlock, setTypeOfBlockCreation, setTabToReturnTo } =
    stateSlice.actions

export default stateSlice.reducer
