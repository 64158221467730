import { createSlice } from '@reduxjs/toolkit'

export const initialUpdatedDataForInformationBlockData = {
    requirementsMapForAdditionalInformation: {}
}

const stateSlice = createSlice({
    name: 'informationBlocks/state',
    initialState: {
        deleteConfirmation: false,
        selectedInformationBlock: '',
        selectedLesson: '',
        informationBlockDialog: false,

        updatedDataForInformationBlock: initialUpdatedDataForInformationBlockData,

        typeOfBlockCreation: '',
        tabToReturnTo: '',
    },
    reducers: {
        setTabToReturnTo: (state, action) => {
            state.tabToReturnTo = action.payload
        },
        setTypeOfBlockCreation: (state, action) => {
            state.typeOfBlockCreation = action.payload
        },
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedInformationBlock: (state, action) => {
            state.selectedInformationBlock = action.payload
        },
        setSelectedLesson: (state, action) => {
            state.selectedLesson = action.payload
        },
        toggleInformationBlockDialog: (state, action) => {
            state.informationBlockDialog = action.payload
        },
        setUpdatedDataForInformationBlock: (state, action) => {
            // console.log('action.payload', action.payload)

            // console.log('looook', {
            //     ...state.updatedDataForInformationBlock,
            //     ...action.payload
            // })
            state.updatedDataForInformationBlock = {
            ...state.updatedDataForInformationBlock,
            ...action.payload
        }
        },
        resetUpdatedDataForInformationBlock: (state, action) => {
            state.updatedDataForInformationBlock = initialUpdatedDataForInformationBlockData
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedInformationBlock, setSelectedLesson, toggleInformationBlockDialog, setUpdatedDataForInformationBlock, resetUpdatedDataForInformationBlock, setTypeOfBlockCreation, setTabToReturnTo } =
    stateSlice.actions

export default stateSlice.reducer
