import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    db,
    doc,
    updateDoc
} from 'configs/firebase.config'

// export const getProducts = createAsyncThunk(
//     'auth/user/getProducts',
//     async (data) => {
//         const response = await fetch(
//             `${appConfig.serverApiPrefix}/payment-methods`,
//             {
//                 method: 'POST',
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                   },
//                   body: JSON.stringify( {
//                     stripeCustomerId
//                 })
//             }
//         )

//         const data = await response.json()

//         const { pageIndex, pageSize, sort, query, firebaseListData } = data
//         const { order, key } = sort
//         const products = firebaseListData
//         const sanitizeProducts = products.filter(
//             (elm) => typeof elm !== 'function'
//         )
//         let data = sanitizeProducts
//         let total = products.length

//         if ((key === 'category' || key === 'name') && order) {
//             data.sort(sortBy(key, order === 'desc', (a) => a.toUpperCase()))
//         } else {
//             data.sort(sortBy(key, order === 'desc', parseInt))
//         }

//         if (query) {
//             data = wildCardSearch(data, query)
//             total = data.length
//         }

//         data = paginate(data, pageSize, pageIndex)

//         const responseData = {
//             data: data,
//             total: total,
//         }

//         return responseData.data
//     }
// )

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        setHasCompletedAContent: (state, action) => {
            state.hasCompletedAContent = true

            //console.log('state', state)

            const userRef = doc(db, 'users', state.id)

            updateDoc(userRef, {
                hasCompletedAContent: true
            })
        },
        userLoggedOut: () => initialState,
    },
})
//console.log('In userSlice.js')

export const { setUser, setHasCompletedAContent } = userSlice.actions

export default userSlice.reducer
