import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'coursesManager/state',
    initialState: {
        deleteConfirmation: false,
        selectedCourse: '',
        selectedLesson: '',

        originalDashboardConfiguration: {},
        defaultDashboardConfiguration: {},
        deleteConfirmationTemplate: false,
        editConfirmationTemplate: false,

        defaultDashboardConfigurationId: '',

        selectedRowData: { id: null },

        selectedSurveyBlockData: '',

        coursePictureMap: {},
        loading: false,
        userPictureMap: {}
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedCourse: (state, action) => {
            state.selectedCourse = action.payload
        },
        setSelectedLesson: (state, action) => {
            state.selectedLesson = action.payload
        },

        setSelectedDashboardTemplate: (state, action) => {
            state.selectedDashboardTemplate = action.payload
        },

        setOriginalDashboardConfiguration: (state, action) => {
            state.originalDashboardConfiguration = action.payload
        },

        setDefaultDashboardConfiguration: (state, action) => {
            state.defaultDashboardConfiguration = action.payload
        },

        setDefaultDashboardConfigurationId: (state, action) => {
            state.defaultDashboardConfigurationId = action.payload
        },

        setSelectedRowData: (state, action) => {
            state.selectedRowData = action.payload
        },

        setSelectedSurveyBlockData: (state, action) => {
            state.selectedSurveyBlockData = action.payload
        },

        setCoursePictureMap: (state, action) => {
            state.coursePictureMap = action.payload
        },

        setLoading: (state, action) => {
            state.loading = action.payload
        },

        toggleEditConfirmationTemplate: (state, action) => {
            state.editConfirmationTemplate = action.payload
        },
        toggleDeleteConfirmationTemplate: (state, action) => {
            state.deleteConfirmationTemplate = action.payload
        },

        setUserPictureMap: (state, action) => {
            state.userPictureMap = action.payload
        },
    },
})

export const {
    toggleDeleteConfirmation,
    setSelectedCourse,
    setSelectedLesson,
    setOriginalDashboardConfiguration,
    setDefaultDashboardConfiguration,
    toggleDeleteConfirmationTemplate,
    setDefaultDashboardConfigurationId,
    toggleEditConfirmationTemplate,
    setSelectedRowData,
    setSelectedSurveyBlockData,
    setSelectedDashboardTemplate,
    setCoursePictureMap,
    setLoading,
    setUserPictureMap
} = stateSlice.actions

export default stateSlice.reducer
